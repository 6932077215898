import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  attorney_LandingPage,
  client_add_bank_details,
  client_LandingPage,
  client_personal_bank_details,
  client_request_bankList_details,
  famVault_wesbite,
} from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  accountNumberRules,
  bankNameRules,
  firstNameRules,
  routingNumberRules,
  swiftCode,
} from "../../constants/regExp";
import * as yup from "yup";
import { validation } from "../../constants/client/validationMessages";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { bankDetailsLabel, clientTitle } from "../../constants/client/labels";
import AttorneyTitleBar from "../attorney/AttorneyTitleBar";
import {
  useAddBankDetailsMutation,
  useRequestAddBankDetailsMutation,
} from "../../slices/wireTransferApiManagementSlice";
import Loader from "../Loader/Loader";
import { accountType } from "../../constants/client/mockData";
import { cliManagement } from "../../constants/attorney/lables";
import { useShareBankDetailsEmailMutation } from "../../slices/wireTransferAuthManagemementSlice";
import BankShareConfirmationPopup from "../modal/BankShareConfirmationPopup";
import { setEmptyDetails } from "../../slices/shareReceiveBankDetailsSlice";
import { FIVE_MINUTES } from "../../constants/idleTimer";
import axios from "axios";
import HeaderProfileWireTransfer from "../headers/HeaderProfileWireTransfer";

export default function AddBankDetails({ refetchData }) {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [buttonName, setButtonName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [bankData, setBankData] = useState({});
  const dispatch = useDispatch();

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
      state: { id: userInfo?.firmTypeId },
    },
  ]);
  const isNotificationPage = location?.state?.page === "requestBankDetails";
  const isEmailPage = location?.state?.page === "requestBankDetailsEmail";
  const isAlreadyShared =
    location?.state?.page === "viewBankDetailEmail" ||
    location?.state?.page === "requestBankDetailsAdd";
  let transactionId = isEmailPage && location?.state?.transactionId;
  let identifierID = isEmailPage && location?.state?.identifierID;
  if (location?.state?.page === "requestBankDetails") {
    const apiUrl = location?.state?.id[0]?.apiUrl;
    const urlObj = new URL(apiUrl);
    const queryParameters = new URLSearchParams(urlObj.search);
    transactionId = queryParameters.get("transactionId");
  }
  const fromOtpPage = sessionStorage.getItem("fromOtpPage"); 



  useEffect(() => {
    if (location?.state?.page === "requestBankDetails") {
      if (userInfo?.role === "ATT") {
        setItems([
          {
            id: "item-1",
            link: attorney_LandingPage,
            content: cliManagement,
            active: false,
          },
        ]);
      }
      setButtonName(bankDetailsLabel?.share);
    } else if (
      location?.state?.page === "viewBankDetailEmail" ||
      location?.state?.page === "requestBankDetailsEmail"
    ) {
      setItems([]);
      setButtonName(bankDetailsLabel?.share);
    } else if (
      location?.state?.page === "bankDetails" ||
      location?.state?.page === "bankDetailsList"
    ) {
      setItems([
        {
          id: "item-1",
          link: client_LandingPage,
          content: clientTitle,
          active: false,
          state: { id: userInfo?.firmTypeId },
        },
        {
          id: "item-2",
          link: client_add_bank_details,
          content: bankDetailsLabel?.bankDetails,
          active: false,
        },
      ]);
      setButtonName(bankDetailsLabel?.save);
    } else {
      setButtonName(bankDetailsLabel?.save);
    }
  }, [location, userInfo]);

  const handleLogout = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/logout?identifier=${location?.state?.identifier}`;
      await axios({
        url: url,
        method: "GET",
      });
      toast.success("Signed out successfully...");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    dispatch(setEmptyDetails());
    sessionStorage.clear();
    window.location.replace(famVault_wesbite);
  }, [dispatch, location?.state?.identifier]);

  useEffect(() => {
    if (userInfo === null && !location?.state) {
      window.location.href = famVault_wesbite;
    }
    if(location?.state?.page === "requestBankDetailsEmail" || location?.state?.page === "viewBankDetailEmail"){
      console.log(fromOtpPage)
      if(fromOtpPage !=="Yes"){
        console.log(fromOtpPage)
        handleLogout()
      }  
    }
  }, [location, navigate, userInfo, fromOtpPage, handleLogout]);

  useEffect(() => {
    let logoutInterval;
    const LOGOUT_INTERVAL = FIVE_MINUTES;
    const handleAutoLogout = () => {
      handleLogout();
    };

    const initializeLogoutTimer = () => {
      const storedTime = sessionStorage.getItem("logoutTime");
      const currentTime = Date.now();

      if (storedTime) {
        const remainingTime = Number(storedTime) - currentTime;

        if (remainingTime > 0) {
          logoutInterval = setInterval(() => {
            const timeLeft = Number(storedTime) - Date.now();

            if (timeLeft <= 0) {
              clearInterval(logoutInterval);
              handleAutoLogout();
            }
          }, 1000);
        } else {
          handleAutoLogout();
        }
      } else {
        const logoutTime = currentTime + LOGOUT_INTERVAL;
        sessionStorage.setItem("logoutTime", logoutTime);
        logoutInterval = setInterval(() => {
          const timeLeft = logoutTime - Date.now();

          if (timeLeft <= 0) {
            clearInterval(logoutInterval);
            handleAutoLogout();
          }
        }, 1000);
      }
    };
    if (location?.state?.page === "requestBankDetailsEmail" || location?.state?.page === "viewBankDetailEmail") {
      initializeLogoutTimer();
    }
  }, [handleLogout, location?.state?.page]);

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(firstNameRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .matches(firstNameRules, `${validation.lastName.rule}`),
    routingNumber: yup
      .string()
      .required(`${validation.routingNumber.required}`)
      .matches(routingNumberRules, {
        message: `${validation.routingNumber.routingNumberRules}`,
      }),
    reRoutingNumber: yup
      .string()
      .required(`${validation.routingNumber.required}`)
      .oneOf([yup.ref("routingNumber"), null], "Routing Number Mismatch"),
    bankName: yup
      .string()
      .trim()
      .required(`${validation.bankName.required}`)
      .matches(bankNameRules, `${validation.bankName.required}`),
    branchName: yup
      .string()
      .trim()
      .required(`${validation.branchName.required}`)
      .matches(bankNameRules, `${validation.bankName.required}`),
    accountType: yup
      .string()
      .notOneOf([""], `${validation.accountType.required}`)
      .required(),
    accountNumber: yup
      .string()
      .required(`${validation.accountNumber.required}`)
      .matches(accountNumberRules, {
        message: `${validation.accountNumber.accountNumberRules}`,
      }),
    reAccNumber: yup
      .string()
      .required(`${validation.accountNumber.required}`)
      .oneOf([yup.ref("accountNumber"), null], "Account Number Mismatch"),
    swiftCode: yup
      .string()
      .trim()
      .matches(swiftCode, `${validation.swiftCode.rule}`),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  useEffect(() => {
    if (isAlreadyShared) {
      const {
        firstName,
        lastName,
        routingNumber,
        bankName,
        branchName,
        accountType,
        accountNumber,
        swiftCode,
      } = location?.state?.data?.data[0];
      setValue("firstName", firstName || "");
      setValue("lastName", lastName || "");
      setValue("routingNumber", routingNumber || "");
      setValue("reRoutingNumber", routingNumber || "");
      setValue("bankName", bankName || "");
      setValue("branchName", branchName || "");
      setValue("accountType", accountType || "");
      setValue("accountNumber", accountNumber || "");
      setValue("reAccNumber", accountNumber || "");
      setValue("swiftCode", swiftCode || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addBankDetail, { isLoading: addBankDetailLoading }] =
    useAddBankDetailsMutation({
      skip: isNotificationPage,
    });

  const [requestShareBankDetail, { isLoading: requestShareBankDetailLoading }] =
    useRequestAddBankDetailsMutation({
      skip: !isNotificationPage,
    });
  const [shareBankDetails, { isLoading: shareBankDetailsLoading }] =
    useShareBankDetailsEmailMutation({
      skip: !isEmailPage,
    });

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "accountNumber") {
      event.target.value = value.slice(0, 30);
    }
    if (name === "routingNumber") {
      event.target.value = value.slice(0, 9);
    }
    if (name === "reRoutingNumber") {
      event.target.value = value.slice(0, 9);
    }
    if (name === "reAccNumber") {
      event.target.value = value.slice(0, 30);
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const onSubmit = async (formData) => {
    if (isEmailPage || isNotificationPage) {
      setShowModal(true);
      setBankData(formData);
    } else {
      const transformData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        branchName: formData.branchName,
        bankName: formData.bankName,
        accountType: formData.accountType,
        accountNumber: formData.accountNumber,
        swiftCode: formData.swiftCode,
        routingNumber: formData.routingNumber,
        userId: userInfo?.userId,
        createdBy: userInfo?.userId,
      };
      const data = JSON.stringify(transformData);
      try {
        const res = await addBankDetail(data).unwrap();
        if (res?.successful) {
          toast(
            <div>
              <div className="upload mt-2">{res?.message}</div>
            </div>,
            {
              autoClose: true,
              closeOnClick: false,
              theme: "light",
              closeButton: true,
              hideProgressBar: true,
              className: "custom-toast-video-sigining",
            }
          );
          if (location?.state?.page === "bankDetailsList") {
            navigate(client_request_bankList_details, {
              state: {
                page: "bankDetailsList",
                transactionId: location?.state?.transactionId,
              },
            });
          } else {
            navigate(client_personal_bank_details);
          }
          refetchData();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.errorMessage);
      }
    }
  };

  const onClickYes = async () => {
    setShowModal(false);
    const requestBodyData = {
      firstName: bankData.firstName,
      lastName: bankData.lastName,
      branchName: bankData.branchName,
      bankName: bankData.bankName,
      accountType: bankData.accountType,
      accountNumber: bankData.accountNumber,
      swiftCode: bankData.swiftCode,
      routingNumber: bankData.routingNumber,
    };
    if (isNotificationPage && userInfo?.firmTypeId !== 2) {
      try {
        const res = await requestShareBankDetail({
          user: {
            transactionId: transactionId,
            userId: userInfo?.userId,
          },
          data: requestBodyData,
        }).unwrap();
        if (res?.successful) {
          toast(
            <div>
              <div className="upload mt-2">{res?.message}</div>
            </div>,
            {
              autoClose: true,
              closeOnClick: false,
              theme: "light",
              closeButton: true,
              hideProgressBar: true,
              className: "custom-toast-video-sigining",
            }
          );
          if (userInfo?.role === "ATT") {
            navigate(attorney_LandingPage);
          } else if (userInfo?.role === "CLI") {
            navigate(client_LandingPage);
          }
          refetchData();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.errorMessage);
      }
    } else if (isEmailPage) {
      const securityKey = location?.state?.securityKey;
      if (securityKey) {
        try {
          const res = await shareBankDetails({
            user: {
              transactionId: transactionId,
              key: securityKey,
              identifierID: identifierID,
            },
            data: requestBodyData,
          }).unwrap();
          if (res?.successful) {
            toast(
              <div>
                <div className="upload mt-2">{res?.message}</div>
              </div>,
              {
                autoClose: true,
                closeOnClick: false,
                theme: "light",
                closeButton: true,
                hideProgressBar: true,
                className: "custom-toast-video-sigining",
              }
            );
            window.location.replace(famVault_wesbite);
          } else {
            toast.error(res?.message);
          }
        } catch (err) {
          toast.error(err?.data?.message || err.errorMessage);
        }
      }
    } else if (userInfo?.firmTypeId === 2) {
      const transformData = {
        firstName: bankData?.firstName,
        lastName: bankData?.lastName,
        branchName: bankData?.branchName,
        bankName: bankData?.bankName,
        accountType: bankData?.accountType,
        accountNumber: bankData?.accountNumber,
        swiftCode: bankData?.swiftCode,
        routingNumber: bankData?.routingNumber,
        userId: userInfo?.userId,
        createdBy: userInfo?.userId,
      };
      const data = JSON.stringify(transformData);

      try {
        const res = await requestShareBankDetail({
          user: {
            transactionId: transactionId,
            userId: userInfo?.userId,
          },
          data: requestBodyData,
        }).unwrap();
        if (res?.successful) {
          try {
            const result = await addBankDetail(data).unwrap();
            if (result?.successful) {
              refetchData();
            } else {
              toast.error(result?.message);
            }
          } catch (err) {
            toast.error(err?.data?.message || err.errorMessage);
          }
          toast(
            <div>
              <div className="upload mt-2">{res?.message}</div>
            </div>,
            {
              autoClose: true,
              closeOnClick: false,
              theme: "light",
              closeButton: true,
              hideProgressBar: true,
              className: "custom-toast-video-sigining",
            }
          );
          if (userInfo?.role === "CLI") {
            navigate(client_LandingPage);
          }
          refetchData();
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.errorMessage);
      }
    }
  };
  const handlePrevPage = () => {
    if (location?.state?.page === "bankDetails") {
      navigate(client_personal_bank_details);
    } else if (location?.state?.page === "bankDetailsList") {
      navigate(client_request_bankList_details, {
        state: {
          page: "bankDetailsList",
          transactionId: location?.state?.transactionId,
        },
      });
    } else {
      navigate(client_LandingPage);
    }
  };

  return location?.state || userInfo ? (
    <>
      {(isEmailPage || isAlreadyShared) && <HeaderProfileWireTransfer />}
      <div
        className="superadmin-container attorney-edit-client-page"
        data-testid="na-firmadmin-addclient"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={false}
              title={bankDetailsLabel.bankDetails}
              navigateToPrev={handlePrevPage}
              hasBackButton={
                isEmailPage || isNotificationPage || isAlreadyShared
                  ? false
                  : true
              }
            />
          </div>
          <div className="firm-page-outer-container firm-page-add-outer-container">
            <div className="firm-page-container employee-form-container">
              <Form
                className="custom-form employee-form px-2 pb-3 px-lg-4 px-md-4 type-2"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col lg={12} md={12} className="mt-4">
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-firstname"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.firstName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            maxLength={30}
                            {...register("firstName")}
                            isInvalid={!!errors.firstName}
                            data-testid="cli-firstname"
                            id="cli-firstname"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.firstName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="cli-lastname" className="mb-0">
                            {bankDetailsLabel.lastName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            autoComplete="off"
                            {...register("lastName")}
                            isInvalid={!!errors.lastName}
                            data-testid="cli-lastname"
                            id="cli-lastname"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.lastName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-routingNumber"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.routingNumber}
                          </Form.Label>
                          <Form.Control
                            className="form-input bank-account-number"
                            type="text"
                            name="routingNumber"
                            inputMode="text"
                            autoComplete="off"
                            onKeyDown={handleKeyPress}
                            onChangeCapture={handleMaxNumber}
                            {...register("routingNumber")}
                            isInvalid={!!errors.routingNumber}
                            data-testid="cli-routingNumber"
                            id="cli-routingNumber"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.routingNumber?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-reRoutingNumber"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.reRoutingNumber}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="reRoutingNumber"
                            autoComplete="off"
                            onKeyDown={handleKeyPress}
                            onChangeCapture={handleMaxNumber}
                            {...register("reRoutingNumber")}
                            isInvalid={!!errors.reRoutingNumber}
                            data-testid="cli-reRoutingNumber"
                            id="cli-reRoutingNumber"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.reRoutingNumber?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-bankName"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.bankName}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="bankName"
                            maxLength={30}
                            autoComplete="off"
                            {...register("bankName")}
                            isInvalid={!!errors.bankName}
                            data-testid="cli-bankName"
                            id="cli-bankName"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.bankName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-branchName"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.branchName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="branchName"
                            inputMode="text"
                            autoComplete="off"
                            maxLength={30}
                            {...register("branchName")}
                            isInvalid={!!errors.branchName}
                            data-testid="cli-branchName"
                            id="cli-branchName"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.branchName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-accountType"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.accountType}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-input input-border-radius-unset select-placeholder"
                            name="city"
                            isInvalid={!!errors.accountType}
                            {...register("accountType")}
                            id="cli-accountType"
                            data-testid="cli-accountType"
                            disabled={isAlreadyShared}
                          >
                            <option value="" hidden></option>
                            {accountType &&
                              accountType.length > 0 &&
                              accountType.map((relation, index) => {
                                return (
                                  <option
                                    key={"relation" + index}
                                    value={relation.id}
                                  >
                                    {relation.label}
                                  </option>
                                );
                              })}
                          </Form.Select>
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.accountType?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-accountNumber"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.accountNumber}
                          </Form.Label>
                          <Form.Control
                            className="form-input bank-account-number"
                            type="text"
                            name="accountNumber"
                            inputMode="text"
                            autoComplete="off"
                            onKeyDown={handleKeyPress}
                            onChangeCapture={handleMaxNumber}
                            {...register("accountNumber")}
                            isInvalid={!!errors.accountNumber}
                            data-testid="cli-accountNumber"
                            id="cli-accountNumber"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.accountNumber?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="cli-reAccNumber"
                            className="required mb-0"
                          >
                            {bankDetailsLabel.reAccNumber}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset account-number-x"
                            type="number"
                            name="reAccNumber"
                            onKeyDown={handleKeyPress}
                            onChangeCapture={handleMaxNumber}
                            autoComplete="off"
                            {...register("reAccNumber")}
                            isInvalid={!!errors.reAccNumber}
                            data-testid="cli-reAccNumber"
                            id="cli-reAccNumber"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.reAccNumber?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="cli-swiftCode" className="mb-0">
                            {bankDetailsLabel.swiftCode}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="swiftCode"
                            autoComplete="off"
                            maxLength={30}
                            {...register("swiftCode")}
                            isInvalid={!!errors.swiftCode}
                            data-testid="cli-swiftCode"
                            id="cli-swiftCode"
                            disabled={isAlreadyShared}
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.swiftCode?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-lg-2 mt-md-2">
                  <Button
                    type="submit"
                    className="custom-button btn-standard btn-reset btn-onboarding submit-z-index"
                    data-testid="submit-button"
                    disabled={isAlreadyShared}
                  >
                    {buttonName}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      {showModal && (
        <BankShareConfirmationPopup
          setShowCallback={() => setShowModal(false)}
          setYesCallback={onClickYes}
          content={`Are you sure you want to share the bank details?`}
          showModal={showModal}
        />
      )}
      {(addBankDetailLoading ||
        requestShareBankDetailLoading ||
        shareBankDetailsLoading) && <Loader />}
    </>
  ) : null;
}
