import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FormTitle from "../components/form/FormTitle";
import Header from "../components/headers/Header";
import { inputFocus } from "../utils/inputMethod";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  famVault_wesbite,
  request_bank_details,
  view_bank_details,
} from "../constants/routes";
import {
  useBankOtpSendMutation,
  useBankReceiveOtpVerifyMutation,
  useBankSendOtpVerifyMutation,
} from "../slices/wireTransferAuthManagemementSlice";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../slices/authSlice";
import ModalPopupType8 from "../components/modal/ModalPopupType8";
import axios from "axios";
import { setEmptyDetails } from "../slices/shareReceiveBankDetailsSlice";

export default function BankDetailsOtpVerification() {
  const otpTime = 30;
  const [seconds, setSeconds] = useState(otpTime);
  const [timerRunning, setTimerRunning] = useState(true);
  const effectRan = useRef(false);
  const hasRun = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  const transactionId = useSelector(
    (state) => state.shareReceiveBankDetails.transactionId
  );
  const checkShare =
    identifierID &&
    requestType &&
    (requestType === "SEND" || requestType === "RECEIVE");

  const [sendOtp, { isLoading: isOtpLoading }] = useBankOtpSendMutation();
  const [sendVerifyOtp, { isLoading: isSendVerifyLoading }] =
    useBankSendOtpVerifyMutation();
  const [receiveVerifyOtp, { isLoading: isReceiveVerifyLoading }] =
    useBankReceiveOtpVerifyMutation();
  const fromWireTransfer = sessionStorage.getItem("fromWireTransfer");
  const sendOtpFunction = useCallback(async () => {
    if (checkShare && fromWireTransfer === "Yes") {
      try {
        const res = await sendOtp({
          identifierID,
          requestType,
          transactionId,
        }).unwrap();

        if (res?.successful) {
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(
          err?.data?.message ||
            err?.message ||
            err?.error?.message ||
            err?.error ||
            err
        );
      }
    }
  }, [
    checkShare,
    identifierID,
    requestType,
    sendOtp,
    transactionId,
    fromWireTransfer,
  ]);

  useEffect(() => {
    let timer;
    if (timerRunning) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          clearInterval(timer);
          setTimerRunning(false);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [seconds, timerRunning]);

  const handleLogout = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/logout?identifier=${identifierID}`;
      await axios({
        url: url,
        method: "GET",
      });
      toast.success("Signed out successfully...");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    dispatch(setEmptyDetails());
    sessionStorage.clear();
    window.location.href = famVault_wesbite;
  }, [dispatch, identifierID]);

  useEffect(() => {
    if (!effectRan.current) {
      sendOtpFunction();
      effectRan.current = true;
    }
    return () => (effectRan.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendOtpFunction]);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;

      if (fromWireTransfer !== "Yes") {
        handleLogout();
      }
    }
  }, [handleLogout, fromWireTransfer]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const timerDisplay = `${minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const handleDigitChange = (event, value) => {
    if (event.target.value.length > 1) {
      setValue(value, event.target.value[event.target.value.length - 1]);
    } else {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      setValue(value, numericValue);
    }
  };

  const onSubmit = async (formData) => {
    const otp = Object.values(formData).toString().replaceAll(",", "");
    const transformData = {
      identifierID: identifierID,
      otp: otp,
      transactionId: transactionId,
    };
    let res;
    if (requestType === "SEND") {
      try {
        res = await sendVerifyOtp(transformData).unwrap();
        dispatch(setCredentials({ ...res }));
        setModalMessage(res?.message);
        if (res?.successful && res?.data !== null) {
          sessionStorage.removeItem("fromWireTransfer");
          sessionStorage.setItem("fromOtpPage", "Yes")
          navigate(view_bank_details, {
            state: {
              page: "viewBankDetailEmail",
              data: res,
              transactionId: transactionId,
              identifier: identifierID,
            },
          });
          // toast.success(res?.message);
        } else {
          setShowModal(true);
        }
      } catch (err) {
        toast.error(
          err?.data?.message ||
            err?.errorMessage ||
            err?.data?.errorMessage ||
            err?.error ||
            err
        );
      }
    } else {
      try {
        res = await receiveVerifyOtp(transformData).unwrap();
        if (res?.successful) {
          toast.success(res?.message);
          const securityKey = res?.key;
          checkBankDetails(securityKey);
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(
          err?.data?.message ||
            err?.errorMessage ||
            err?.data?.errorMessage ||
            err?.error ||
            err
        );
      }
    }
  };

  const checkBankDetails = async (key) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/bank-detail?transactionId=${transactionId}&key=${key}&identifier=${identifierID}`;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
      });
      if (response.status === 200) {
        sessionStorage.removeItem("fromWireTransfer");
        sessionStorage.setItem("fromOtpPage", "Yes")
        if (response?.data?.data !== null) {
          toast(
            <div>
              <div className="upload mt-2">
                Bank detail has been already submitted. if you want to update
                the shared detail please request initiator to initiate new
                transaction.
              </div>
            </div>,
            {
              autoClose: false,
              closeOnClick: false,
              theme: "light",
              closeButton: true,
              hideProgressBar: true,
              className: "custom-toast-video-sigining",
            }
          );
          navigate(request_bank_details, {
            state: {
              page: "viewBankDetailEmail",
              data: response?.data,
              transactionId: transactionId,
              identifierID: identifierID,
            },
          });
        } else {
          sessionStorage.removeItem("fromWireTransfer");
        sessionStorage.setItem("fromOtpPage", "Yes")
          navigate(request_bank_details, {
            state: {
              page: "requestBankDetailsEmail",
              transactionId: transactionId,
              identifierID: identifierID,
              securityKey: key,
            },
          });
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(
        err?.data?.message ||
          err?.message ||
          err?.error?.message ||
          err?.error ||
          err
      );
    }
  };

  const handleResendOTP = async () => {
    reset();
    setSeconds(otpTime);
    setTimerRunning(true);
    try {
      const res = await sendOtp({
        identifierID,
        requestType,
        transactionId,
      }).unwrap();

      if (res?.successful) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(
        err?.data?.message ||
          err?.message ||
          err?.error?.message ||
          err?.error ||
          err
      );
    }
  };

  return (
    <>
      <Header />
      <div className="otp-verification-container">
        <Container fluid>
          <Row>
            <Col xs={12} lg={7} className="d-none d-lg-block p-0">
              <div className="change-pwd-web-image"></div>
            </Col>
            <Col md={12} sm={12} lg={5} xs={12}>
              <div className="d-flex justify-contant-lg-center justify-content-md-center justify-content-sm-center justify-align-container">
                <div className="inner-shareotp-container">
                  <Form
                    className="custom-form w-100 type-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormTitle title="OTP Verification" variant="variant-2" />
                    <Form.Group>
                      <Form.Label
                        htmlFor="photp1"
                        className="custom-form-label custom-label-shareotp"
                      >
                        Enter the code sent to your Mobile Phone
                      </Form.Label>
                      <div className="ms-0 d-flex justify-content-between">
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp1"
                          name="photp1"
                          data-testid="photp1-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          autoFocus={true}
                          min={0}
                          max={9}
                          {...register("photp1", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp1")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="1"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp2"
                          name="photp2"
                          data-testid="photp2-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp2", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp2")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="2"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp3"
                          name="photp3"
                          data-testid="photp3-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp3", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp3")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="3"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp4"
                          name="photp4"
                          data-testid="photp4-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp4", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp4")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          //isInvalid={!!errors.otp4}
                          tabIndex="4"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp5"
                          name="photp5"
                          data-testid="photp5-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp5", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp5")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="5"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp6"
                          name="photp6"
                          data-testid="photp6-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp6", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp6")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="6"
                        />
                      </div>
                      {errors &&
                        (errors?.photp1 ||
                          errors?.photp2 ||
                          errors?.photp3 ||
                          errors?.photp4 ||
                          errors?.photp5 ||
                          errors?.photp6) && (
                          <Row>
                            <Col className="mt-1">
                              <span className="otp-verify-error">
                                Please Enter 6 Digit OTP
                              </span>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end  mt-2">
                                <div className="custom-resend-otp">
                                  <span
                                    onClick={handleResendOTP}
                                    className={`btn-resent-otp ${
                                      seconds === 0 ? "active" : "disabled"
                                    }`}
                                  >
                                    Resend OTP
                                  </span>{" "}
                                  in {timerDisplay}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                    </Form.Group>
                    {!(
                      errors &&
                      (errors?.photp1 ||
                        errors?.photp2 ||
                        errors?.photp3 ||
                        errors?.photp4 ||
                        errors?.photp5 ||
                        errors?.photp6)
                    ) && (
                      <div className="d-flex justify-content-end  mt-2">
                        <div className="custom-resend-otp">
                          <span
                            onClick={handleResendOTP}
                            className={`btn-resent-otp ${
                              seconds === 0 ? "active" : "disabled"
                            }`}
                          >
                            Resend OTP
                          </span>{" "}
                          in {timerDisplay}
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end  mt-4">
                      <Button
                        className="custom-button btn-standard btn-shareotp-submit"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      <ModalPopupType8
        setShowCallback={() => setShowModal(false)}
        singleOkButton
        setOkCallback={() => (window.location.href = `${famVault_wesbite}`)}
        content={modalMessage}
        showModal={showModal}
      />
      {(isOtpLoading || isSendVerifyLoading || isReceiveVerifyLoading) && (
        <Loader />
      )}
    </>
  );
}
