import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  client_personal_bank_details,
  client_LandingPage,
  client_view_bank_details,
  attorney_LandingPage,
  client_request_bankList_details,
  famVault_wesbite,
} from "../../constants/routes";
import AttorneyTitleBar from "../../components/attorney/AttorneyTitleBar";
import { useDispatch, useSelector } from "react-redux";
import { bankDetailsLabel, clientTitle } from "../../constants/client/labels";
import {
  useGetBankDetailsQuery,
  useGetShareBankDetailsQuery,
} from "../../slices/wireTransferApiManagementSlice";
import Loader from "../Loader/Loader";
import { cliManagement } from "../../constants/attorney/lables";
import { toast } from "react-toastify";
import axios from "axios";
import { ReactComponent as DownloadIcon } from "../../assets/images/DownloadSimple.svg";
import { setEmptyDetails } from "../../slices/shareReceiveBankDetailsSlice";
import { FIVE_MINUTES } from "../../constants/idleTimer";
import HeaderProfileWireTransfer from "../headers/HeaderProfileWireTransfer";

export default function ViewBankDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const [isDownloading, setIsDownloading] = useState(false);
  const [progressMap, setProgressMap] = useState({});
  const isNotificationPage = location?.state?.page === "viewBankDetailNoti";
  const isEmailPage = location?.state?.page === "viewBankDetailEmail";
  const dispatch = useDispatch();
  const fromOtpPage = sessionStorage.getItem("fromOtpPage"); 
  const { data: shareData, isLoading: isShareLoading } =
    useGetShareBankDetailsQuery(
      {
        transactionId: location?.state?.id?.transactionId,
        userId: location?.state?.userId,
      },
      { skip: !isNotificationPage || isEmailPage }
    );

  const { data: bankData, isLoading: isBankLoading } = useGetBankDetailsQuery(
    {
      bankDetailId: location?.state?.id?.bankDetailId,
      userId: location?.state?.userId,
    },
    { skip: isNotificationPage || isEmailPage || userInfo === null }
  );
  const data = isEmailPage
    ? location?.state?.data
    : isNotificationPage
    ? shareData
    : bankData;
  const isLoading = isShareLoading || isBankLoading;

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
    },
    {
      id: "item-2",
      link: client_personal_bank_details,
      content: bankDetailsLabel.allAccounts,
      active: false,
    },
    {
      id: "item-3",
      link: client_view_bank_details,
      content: bankDetailsLabel.bankDetails,
      active: true,
    },
  ]);

  useEffect(() => {
    if (data && data.data === null) {
      toast.error("Account not found");
    }
  }, [data]);

  useEffect(() => {
    if (location?.state?.page === "viewBankDetailNoti") {
      if (userInfo?.role === "CLI") {
        setItems([
          {
            id: "item-1",
            link: client_LandingPage,
            content: clientTitle,
            active: false,
          },
        ]);
      } else if (userInfo?.role === "ATT") {
        setItems([
          {
            id: "item-1",
            link: attorney_LandingPage,
            content: cliManagement,
            active: false,
          },
        ]);
      }
    }
    if (location?.state?.page === "viewBankDetailEmail") {
      setItems([]);
    }
    if (location?.state?.page === "bankDetailsList") {
      setItems([
        {
          id: "item-1",
          link: client_request_bankList_details,
          content: bankDetailsLabel?.sendBankDetails,
          active: false,
        },
        {
          id: "item-2",
          link: client_view_bank_details,
          content: bankDetailsLabel.bankDetails,
          active: true,
        },
      ]);
    }
  }, [location, userInfo]);

  const handleLogout = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/logout?identifier=${location?.state?.identifier}`;
      await axios({
        url: url,
        method: "GET",
      });
      toast.success("Signed out successfully...");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    dispatch(setEmptyDetails());
    sessionStorage.clear();
    window.location.replace(famVault_wesbite);
  }, [dispatch, location?.state?.identifier]);

  useEffect(() => {
    if (!location.state) {
      window.location.href = famVault_wesbite;
    }
    if(isEmailPage){
      if(fromOtpPage !=="Yes"){
        handleLogout()
      }  
    }
  }, [location, navigate, handleLogout, fromOtpPage, isEmailPage]);
  
  useEffect(() => {
    let logoutInterval;
    const LOGOUT_INTERVAL = FIVE_MINUTES;
    const handleAutoLogout = () => {
      handleLogout();
    };

    const initializeLogoutTimer = () => {
      const storedTime = sessionStorage.getItem("logoutTime");
      const currentTime = Date.now();

      if (storedTime) {
        const remainingTime = Number(storedTime) - currentTime;

        if (remainingTime > 0) {
          logoutInterval = setInterval(() => {
            const timeLeft = Number(storedTime) - Date.now();

            if (timeLeft <= 0) {
              clearInterval(logoutInterval);
              handleAutoLogout();
            }
          }, 1000);
        } else {
          handleAutoLogout();
        }
      } else {
        const logoutTime = currentTime + LOGOUT_INTERVAL;
        sessionStorage.setItem("logoutTime", logoutTime);
        logoutInterval = setInterval(() => {
          const timeLeft = logoutTime - Date.now();

          if (timeLeft <= 0) {
            clearInterval(logoutInterval);
            handleAutoLogout();
          }
        }, 1000);
      }
    };
    if (isEmailPage) {
      initializeLogoutTimer();
    }
  }, [isEmailPage, handleLogout]);

  const handlePrevPage = () => {
    if (location?.state?.page === "bankDetailsList") {
      navigate(client_request_bankList_details);
    } else {
      navigate(client_personal_bank_details, {
        state: {
          id: userInfo?.firmTypeId,
        },
      });
    }
  };

  const handleDownload = async () => {
    const headers = {
      "Access-Control-Allow-Credentials": true,
    };
    if (!isEmailPage) {
      const token = userInfo?.accessToken;
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
    }
    const securityKey =
      location?.state?.data?.key || location?.state?.id?.securityKey;

    const bankDetailId = isNotificationPage
      ? shareData?.data[0]?.bankDetailId
      : bankData?.data[0]?.bankDetailId;
    const baseUrlOtp = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/generate/pdf?transactionId=${location?.state?.transactionId}&key=${securityKey}&identifier=${location?.state?.identifier}`;
    const baseUrlNotification = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/banking/pdf/bank-detail?userId=${location?.state?.userId}&bankDetailId=${bankDetailId}`;
    try {
      const response = await axios({
        url: isEmailPage ? baseUrlOtp : baseUrlNotification,
        method: "GET",
        responseType: "blob",
        headers: headers,
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          const docName = "File";
          if (totalLength !== null) {
            setIsDownloading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docName]: { progress, docName },
            }));
          }
        },
      });

      if (response.status === 200) {
        setIsDownloading(true);
        const contentDisposition = response.headers["content-disposition"];
        let fileName = "bank_details";
        if (contentDisposition && contentDisposition.includes("filename=")) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches[1]) {
            fileName = matches[1];
          }
        }
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
        setProgressMap({});
        toast.success(`File has been downloaded successfully!`);
      }
    } catch (error) {
      setIsDownloading(false);
      setProgressMap({});
      if (error?.response?.status === 401) {
        toast.error(`Error : Unauthorized access`);
        try {
          const url = `${process.env.REACT_APP_BASE_URL_BANKINGSERVICE}/api/v1/transactions/logout?identifier=${location?.state?.identifier}`;
          await axios({
            url: url,
            method: "GET",
          });
          dispatch(setEmptyDetails());
          sessionStorage.clear();
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
        dispatch(setEmptyDetails());
        sessionStorage.clear();
        window.location.replace(famVault_wesbite);
      } else {
        console.error("Error downloading file:", error);
        toast.error(`File downloading failed. Try again!`);
      }
    }
  };

  return location?.state || userInfo ? (
    <>
      {isEmailPage && <HeaderProfileWireTransfer />}
      <div
        className="superadmin-container"
        data-testid="non-attorney-view-client-page"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={true}
              buttonText={
                <div>
                  <DownloadIcon
                    className="me-2"
                    style={{ height: "20px", width: "20px" }}
                  />
                  Download
                </div>
              }
              navigateTo={handleDownload}
              width="auto"
              title={bankDetailsLabel?.bankDetails}
              navigateToPrev={handlePrevPage}
              hasBackButton={isEmailPage || isNotificationPage ? false : true}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container">
        <Container fluid className="px-lg-4">
          <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3">
            <div className="employee-view-container pt-3">
              <div className="view-details">
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Name</div>
                    <div className="firm-view-contant">
                      {data?.data
                        ? `${data.data[0]?.firstName} ${data.data[0].lastName}`
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-4">
                    <div className="firm-view-label">Routing Number</div>
                    <div className="firm-view-contant">
                      {data?.data ? `${data.data[0]?.routingNumber}` : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Bank Name</div>
                    <div className="firm-view-contant">
                      {data?.data ? `${data.data[0]?.bankName}` : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-4">
                    <div className="firm-view-label">Branch Name</div>
                    <div className="firm-view-contant">
                      {data?.data ? `${data.data[0]?.branchName}` : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Account Type</div>
                    <div className="firm-view-contant">
                      {data?.data ? `${data.data[0]?.accountType}` : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-4">
                    <div className="firm-view-label">Account Number</div>
                    <div className="firm-view-contant">
                      {data?.data ? `${data.data[0]?.accountNumber}` : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Swift Code</div>
                    <div className="firm-view-contant">
                      {data?.data && data.data[0]?.swiftCode
                        ? data.data[0]?.swiftCode
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {(isLoading || isDownloading) && <Loader />}
      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(
            ([docId = "docId", { progress }]) => (
              <div key={docId} className="progress-bar-document">
                <div className="d-flex flex-column">
                  <div>File downloading</div>
                  <div className="d-flex align-items-center">
                    <progress value={progress} max={100} />{" "}
                    <div className="ps-2">{progress}%</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  ) : null;
}
