import React, { useState } from "react";
import { Container, Button, Tabs, Tab, Alert, Row, Col } from "react-bootstrap";
import ViewFamilyDetails from "../attorney/viewPeopleInformation/ViewFamilyDetails";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import { useSelector } from "react-redux";
import { useGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";

export default function ClientOffCanvasViewProfile({
  handleEditClient,
  handleEditFamily,
  handleAddFamily,
  activeKey
}) {
  const [activeTab, setActiveTab] = useState(activeKey ? activeKey : "profile");
  const { userInfo } = useSelector((state) => state.auth);
  const userId  =  userInfo?.role === "EXE" ? userInfo?.clientId : userInfo?.userId
  const { data: familyData, error: familyerror } = useGetStakeHolderDetailsQuery(userId, {
    skip: userId === undefined,
  });
  const { data, error } = useGetClientQuery(userId, {
    skip: userId === undefined,
  });
  const dateString = data?.DOB || "";
  let formattedDate = "";
  if (dateString) {
    // const date = new Date(dateString);
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // formattedDate = `${month < 10 ? "0" : ""}${month}-${
    //   day < 10 ? "0" : ""
    // }${day}-${year}`;
    formattedDate = dateString.split('T')[0]
  }
  const tabtitle = userInfo?.role === "EXE" ? "Client Profile" : "My Profile"
  const handleEditClick = () => {
    if (activeTab === "profile") {
      handleEditClient();
    } else if (activeTab === "family-info") {
      if (data?.familyMember?.firstName === undefined) {
        handleAddFamily();
      } else {
        handleEditFamily();
      }
    }
  };
  const addEmergencyContact =  window.innerWidth >= 768 ? "Add Emergency Contact" : "Add"
  if (error || familyerror) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div data-testid="attorney-view-family-page">
        <Container fluid>
          <div className="position-relative pt-1">
            {userInfo?.role !== "EXE" && (
              <Button
              style={{ position: "absolute", right: "0px", top: "4px", width: "fit-content", minWidth: "90px" }}
              className="custom-button btn-standard btn-new-firm mt-xs-3 ms-3"
              type="button"
              data-testid="new-firm-onboard-button"
              onClick={handleEditClick}
            >
              {activeTab === "family-info" && familyData?.familyMember?.firstName === undefined ? addEmergencyContact : "Edit"}
            </Button>
          )}
            <Tabs
              defaultActiveKey={activeKey}
              className="nav-tabs-custom"
              onSelect={(k) => setActiveTab(k)}
            >
              <Tab
                eventKey="profile"
                title={tabtitle}
                className="nav-tabs-custom nav-item"
              >
                <div>
                  <div className="divider-firm-tab"></div>
                </div>
                {data ? (
                  <Container className="employee-view-container pt-3 ps-3 mt-1">
                    <div className="view-details">
                      <Row className="align-items-start">
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Name</div>
                          <div className="firm-view-content" data-testid="client-name">
                            {data?.fullName ? data?.fullName : "--"}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Mother’s Maiden Name</div>
                          <div className="firm-view-content" data-testid="client-dob">
                            {data?.maidenName ? data?.maidenName : "--"}
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Email</div>
                          <div className="firm-view-content" data-testid="client-email">
                            {data?.email ? data?.email : "--"}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Mobile Phone</div>
                          <div className="firm-view-content" data-testid="client-mobile">
                            {data?.phone ? data?.phone : "--"}
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">DOB</div>
                          <div className="firm-view-content" data-testid="client-dob">
                            {data?.DOB ? formattedDate : "--"}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Address</div>
                          <div className="firm-view-content" data-testid="client-address">
                            {data?.address?.addressLine2
                              ? `${data?.address?.addressLine1}, ${data?.address?.addressLine2}`
                              : data?.address?.addressLine1
                                ? `${data?.address?.addressLine1}`
                                : "--"}
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Zip</div>
                          <div className="firm-view-content" data-testid="client-zip">
                            {data?.address?.zip ? data?.address?.zip : "--"}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">City</div>
                          <div className="firm-view-content" data-testid="client-city">
                            {data?.address?.city ? data?.address?.city : "--"}
                          </div>
                        </Col>                        
                      </Row>
                      <Row className="align-items-start">
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">State</div>
                          <div className="firm-view-content" data-testid="client-state">
                            {data?.address?.state ? data?.address?.state : "--"}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12} className="pb-3">
                          <div className="firm-view-label">Country</div>
                          <div className="firm-view-content" data-testid="client-country">
                            {data?.address?.country ? data?.address?.country : "--"}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                ) : (
                  <Container className="employee-view-container pt-3 ps-3">
                    No Data found
                  </Container>
                )}
              </Tab>
              <Tab
                eventKey="family-info"
                title="Emergency Contact"
                className="nav-tabs-custom nav-item"
              >
                <div>
                  <div className="divider-firm-tab mb-1"></div>
                </div>
                <ViewFamilyDetails data={familyData} handleAddFamily={handleAddFamily} />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
    </>
  );
}
